// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "19.8.0",
  "buildNumber": "9915",
  "gitSha": "9ea99a72c338a132ae1ca83f363e16b2c95d920b",
  "fullVersion": "19.8.0-9915",
  "formattedVersion": "19.8.0 (9915-9ea99a72c338a132ae1ca83f363e16b2c95d920b)",
  "copyrightYear": "2019"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/19.8.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/19.8.0/${stripLeadingPrefix(suffix, '#')}`
}
    
